@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins !important;
}

html {
  font-size: 13px;
}

body {
  overflow: hidden;
}

.zoom-icons {
  position: absolute;
  top: 0;
  z-index: 10000000;
  padding: 5px 20px;
  left: 18%;
  width: auto;
}

.settings {
  position: absolute;
  width: 18%;
  height: 100vh;
  z-index: 1000;
  box-shadow: 5px 0px 30px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 0.65);
  overflow: auto;
}

@media only screen and (max-width: 1280px) {
  .settings {
    width: 27%;
  }

  .zoom-icons {
    left: 27%;
  }
}

@media only screen and (max-width: 700px) {
  .settings {
    width: 100%;
    position: relative;
  }

  .zoom-icons {
    display: none;
  }

  body {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #202c5c !important;
}

.MuiSlider-thumbColorSecondary:hover {
  box-shadow: rgba(61, 74, 125, 0.18) 0px 0px 0px 8px !important;
}

.MuiRadio-colorSecondary:hover {
  background-color: rgba(61, 74, 125, 0.1) !important;
}

.setting-header {
  position: relative;
  box-shadow: 0px 10px 35px #04000045;
  border-bottom: 2px solid white;
  border-bottom-right-radius: 1px;
  background: rgba(255, 255, 255, 0.5);
  margin-top: 4.5rem;
}

.setting-header:first-child {
  margin-top: 0;
}

.setting-header:after {
  position: absolute;
  left: 10%;
  bottom: -11px;
  width: 20px;
  height: 20px;
  content: '';
  transform: rotateZ(45deg);
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  background: rgba(255, 255, 255, 0.7);
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: white;
  z-index: 1000000;
  transition: 0.5s all;
}

svg * {
  /* transition: 0.1s fill; */
}

.st0 {
  fill: #3c3c3c !important;
}

.svg-box {
  bottom: -1.5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: 0.05s opacity, 0.05s box-shadow;
  box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.09);
  z-index: 1000000000 !important;
}

.MuiTooltip-tooltip {
  font-size: 11px !important;
}

.inactive {
  opacity: 0.35;
  pointer-events: none;
  user-select: none;
  transition: 0.1s all;
}

.palette img {
  border-radius: 2px;
  margin: 5px;
  opacity: 0.7;
  cursor: pointer;
  transition: 0.2s all;
}

.MuiFormControlLabel-root > .MuiFormControlLabel-label,
.standard-font {
  color: #626262 !important;
  transition: 0.2s color;
}

.MuiFormControlLabel-root:hover > .MuiFormControlLabel-label {
  color: black !important;
}

.oscieznica-inner {
  filter: drop-shadow(-2px 7px 6px rgba(0, 0, 0, 0.6));
  -webkit-filter: drop-shadow(-2px 7px 6px rgba(0, 0, 0, 0.6));
}

svg image {
  image-rendering: -webkit-optimize-contrast;
}

image.handle {
  image-rendering: unset;
}

.palette img:hover {
  opacity: 1 !important;
}

.MuiSlider-colorPrimary {
  color: #364a99 !important;
}

.MuiSlider-colorSecondary {
  color: #202c5c !important;
}

.svg-container,
.logo {
  box-shadow: 100px 120px 250px #0000003e;
}

@keyframes bgchange {
  from {
    background-color: rgb(200, 200, 200);
  }
  to {
    background-color: white;
  }
}

#door {
  stroke: #0c10134f;
  stroke-width: 2px;
}

.glazing {
  /* filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 1));
  -webkit-filter: drop-shadow(0px 0px 25px rgba(255, 255, 255, 0.9)); */
  transition: 0.18s all, 3.15s filter, 3.15s -webkit-filter;
}

.glazing-frame {
  transition: 0.3s fill;
}

.stamping {
  transition: 0.18s all;
}

.floor {
  z-index: -1000000000000000 !important;
  width: 100vw;
  height: 100vh;
  transform: perspective(500px) rotateX(30deg) !important;
  -webkit-transform: perspective(500px) rotateX(30deg) !important;
  top: 0;
}

text {
  font-family: Arial;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 5.5px;
  border: 5.5px solid #364a99;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #364a99 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: rgb(31, 42, 84);
  animation: lds-facebook 0.7s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.svgEmbossings *,
.svgEmbossings {
  transition: 0.17s all;
}
